<template>
  <div class="other-page-edit">
    <p class="page-title">其他頁面設定</p>
    <div class="card-container common">
      <p class="page-title">基本設定</p>
      <BaseElForm ref="title" label-position="top" :model="formData" :rules="formRules" hide-required-asterisk>
        <BaseElFormItem label="標題名稱" prop="title">
          <BaseElInput v-model="formData.title" placeholder="請輸入" />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <div class="card-container common">
      <p class="page-title" style="margin-bottom: 12px">頁面說明</p>
      <BaseElForm ref="content" :model="formData" :rules="formRules">
        <BaseElFormItem prop="content">
          <quillEditor ref="quill" v-model="formData.content" :options="editorOption" />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <div class="message-tab-footer">
      <BaseElButton
        style="width: 140px"
        plain
        @click="$router.go(-1)"
      >
        取消
      </BaseElButton>
      <BaseElButton style="width: 140px" type="primary" @click="confirm">
        {{ pageType === 'edit'? '確認' : '新增' }}
      </BaseElButton>
    </div>
  </div>
</template>

<script>
import { UpdatePageSetting, FindPageSetting, CreateCustomizePage } from '@/api/ecommerce/pageSetting'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { mapGetters } from 'vuex'
import { get } from 'lodash'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'

export default {
  name: 'OtherPageEdit',
  components: { quillEditor },
  computed: {
    ...mapGetters(['shop']),
    pageType () {
      return this.$route.query.type
    },
  },
  data: () => ({
    formData: {
      title: '',
      content: '',
    },
    formRules: {
      title: [noEmptyRules()],
      content: [noEmptyRules()],
    },
    editorOption: {
      placeholder: '請輸入頁面說明',
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    },
  }),

  async mounted () {
    if (this.pageType === 'edit') await this.FindPage()
  },
  methods: {
    async confirm () {
      if (!await formUtils.checkForm(this.$refs.title)) return
      if (!await formUtils.checkForm(this.$refs.content)) return
      if (this.pageType === 'create') await this.createCustomizePage()
      if (this.pageType === 'edit') await this.updatePageSetting()
      this.$router.go(-1)
    },
    async createCustomizePage () {
      const [, err] = await CreateCustomizePage({
        shopId: this.shop,
        title: this.formData.title,
        content: {
          areas: [
            {
              enable: true,
              type: 'customize',
              customize: {
                html: this.formData.content,
              },
            },
          ],
        },
      })
      if (err) return this.$message.error(err)
    },

    async updatePageSetting () {
      const [, err] = await UpdatePageSetting({
        shopId: this.shop,
        key: this.$route.params.pageKey,
        title: this.formData.title,
        content: {
          areas: [
            {
              enable: true,
              type: 'customize',
              customize: {
                html: this.formData.content,
              },
            },
          ],
        },
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
    },

    async FindPage () {
      const [res, err] = await FindPageSetting({ shopId: this.shop, key: this.$route.params.pageKey })
      if (err) return this.$message.error(err)
      const html = get(res, 'content.areas[0].customize.html')
      this.formData.title = res.title
      this.formData.content = html
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  @apply text-[24px] font-medium;
}
.other-page-edit {
  @apply grid gap-[25px];
}
.common {
  @apply px-[35px] py-[12px];
}
.message-tab-footer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 30px;
  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  background: white;
  z-index: 2;
}

::v-deep .el-form-item__label{
  @apply p-0;
}

::v-deep .ql-container  {
  @apply h-[300px];
}
</style>
